'use client'

import type { AssetStoryblok } from '@vendure/codegen/storyblok'
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from '@vendure/components/ui'
import type React from 'react'
import { useIntl } from 'react-intl'
import { CustomImage } from './CustomImage'
import { CustomLink } from './CustomLink'

interface LogoProps {
  file: AssetStoryblok
}

export const Logo: React.FC<LogoProps> = ({ file }) => {
  const intl = useIntl()

  const onCopyWordmark = async () => {
    try {
      // @ts-ignore
      if (ClipboardItem.supports('image/svg+xml')) {
        const data = await fetch(file.filename)
        const blob = await data.blob()
        await navigator.clipboard.write([
          new ClipboardItem({
            [blob.type]: blob,
          }),
        ])
        console.log('Fetched image copied.')
      } else {
        console.log('SVG images are not supported by the clipboard.')
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <ContextMenu>
      <ContextMenuTrigger asChild>
        <CustomLink href="/">
          <CustomImage
            src={file.filename}
            alt="Vendure"
            style={{
              height: '20px',
              width: 'auto',
              marginBottom: '7px',
              color: 'var(--tw-color-primary-400)',
            }}
            height="25"
            width="200"
          />
        </CustomLink>
      </ContextMenuTrigger>
      <ContextMenuContent>
        {/*        <ContextMenuItem>
          {intl.formatMessage({ id: 'navigation.logo.copy_logo_svg' })}
        </ContextMenuItem>*/}
        <ContextMenuItem onClick={onCopyWordmark}>
          {intl.formatMessage({ id: 'navigation.logo.copy_wordmark_svg' })}
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  )
}
