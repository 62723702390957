'use client'

import { StoryblokComponent } from '@storyblok/react/rsc'
import type { RichtextStoryblok } from '@vendure/codegen/storyblok'
import { cn } from '@vendure/helpers'
import { removeColorProperty } from '@vendure/storyblok-helpers'
import Link from 'next/link'
import { ComponentProps } from 'react'
import { CopyBlock, tomorrowNightBlue } from 'react-code-blocks'
import { FiArrowUpRight } from 'react-icons/fi'
import {
  MARK_LINK,
  NODE_CODEBLOCK,
  NODE_IMAGE,
  StoryblokRichtext,
  render,
} from 'storyblok-rich-text-react-renderer'
import { CustomImage } from './CustomImage'
import { CustomLink } from './CustomLink'

interface RichTextProps {
  document?: StoryblokRichtext | RichtextStoryblok
  containerProps?: ComponentProps<'div'>
}

export const RichText: React.FC<RichTextProps> = (props) => {
  // document is the rich text object you receive from Storyblok,
  // in the form { type: "doc", content: [ ... ] }

  const { document, containerProps } = props

  return (
    <div
      {...props.containerProps}
      className={cn(
        'rich-text-container prose prose-slate prose-headings:font-accent prose-headings:text-white',
        containerProps?.className,
      )}
    >
      {render(removeColorProperty(document), {
        markResolvers: {
          [MARK_LINK]: (children, props) => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (props === undefined) {
              // Internal links: map to <Link>
              return (
                <CustomLink
                  href=""
                  passHref
                  legacyBehavior
                >
                  <a>{children}</a>
                </CustomLink>
              )
            }
            const { linktype, href, target } = props

            if (linktype === 'email') {
              // Email links: add `mailto:` scheme and map to <a>
              return <Link href={`mailto:${href ?? ''}`}>{children}</Link>
            }
            if (href?.match(/^(https?:)?\/\//)) {
              // External links: map to <a>
              return (
                <CustomLink
                  className="relative inline-flex items-center font-semibold text-white/80 underline"
                  href={href}
                  target={target}
                >
                  {children}
                  <FiArrowUpRight />
                </CustomLink>
              )
            }
            // Internal links: map to <Link>
            return (
              <CustomLink
                href={href ?? ''}
                className={
                  'text-primary-400 hover:text-primary-300 underline transition-colors'
                }
              >
                {children}
              </CustomLink>
            )
          },
        },
        nodeResolvers: {
          [NODE_CODEBLOCK]: (children, props) => {
            const language =
              props.class && typeof props.class === 'string'
                ? props.class.replace('language-', '')
                : 'text'

            return (
              <div className="my-6">
                <CopyBlock
                  language={language}
                  theme={tomorrowNightBlue}
                  text={children}
                />
              </div>
            )
          },
          [NODE_IMAGE]: (children, { src, alt, title }) => {
            if (!src) {
              return null
            }

            return (
              <CustomImage
                src={src}
                width="800"
                height="800"
                className="mx-auto my-10 h-auto w-full rounded-md"
                alt={alt ?? ''}
              />
            )
          },
        },
        defaultBlokResolver(name, props) {
          const blok = {
            component: name,
            ...props,
          }
          return (
            <StoryblokComponent
              blok={blok}
              key={blok._uid}
            />
          )
        },
      })}
    </div>
  )
}
