import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@vendure/components/ui'
import { cn } from '@vendure/helpers'
import moment from 'moment'
import Image from 'next/image'
import Link from 'next/link'
import { Fragment } from 'react'
import { FormattedDate, GradientCard, Heading } from '../atoms'

export interface AgendaSlot {
  start: Date
  end: Date
  title?: string
  subTitle?: string
  onClick?: () => void
  active?: boolean
  speakers?: Array<{
    image?: string
    name?: string
    role?: string
  }>
  payment?: {
    link?: string
    seats?: number
    price?: number
  }
}

export interface AgendaLane {
  title?: string
  date?: Date
  slots?: AgendaSlot[]
}

interface AgendaProps {
  lanes?: AgendaLane[]
  startHour: Date
  endHour: Date
}

function getHourRange(start: Date, end: Date) {
  end.setDate(start.getDate())
  end.setMonth(start.getMonth())

  return moment(end).diff(moment(start), 'hours')
}

export function Agenda({ lanes, startHour, endHour }: AgendaProps) {
  const start = startHour.getHours()
  const end = endHour.getHours()

  // hours span
  const hoursRange = getHourRange(startHour, endHour)
  const maxSlots = hoursRange * 4

  return (
    <div
      style={{
        gridTemplateRows: `100px repeat(${maxSlots},minmax(0,50px))`,
        gridTemplateColumns: `50px repeat(${lanes?.length}, minmax(300px, 1fr))`,
      }}
      className="grid grid-flow-col overflow-x-scroll md:overflow-x-visible"
    >
      {[...Array(hoursRange)].map((_, i) => {
        return (
          <div
            key={`hour-${i}`}
            className={cn({
              'row-span-4 flex  items-start justify-end pr-2': true,
              'row-start-2': i === 0,
            })}
          >
            <span className="-translate-y-1/2">{start + 1 + i}:00</span>
          </div>
        )
      })}
      {lanes?.map((lane, i) => {
        let totalSlotsRendered = 0
        return (
          <Fragment key={`lane-${i}`}>
            <div
              className={cn({
                'flex flex-col items-start justify-center p-4': true,
              })}
            >
              <Heading
                as="span"
                className="block text-xl"
              >
                {lane.title}
              </Heading>
              <FormattedDate
                date={lane.date}
                className="block"
              />
            </div>
            {lane.slots?.map((item, l) => {
              const diff = moment(item.end).diff(moment(item.start), 'minutes')
              const spans = diff / 15
              totalSlotsRendered += spans

              return (
                <div
                  style={{
                    gridRow: `span ${spans} / span ${spans}`,
                  }}
                  className={cn({
                    'border-t border-dashed border-slate-800 p-2 ': true,
                    'border-r': i === 0,
                  })}
                  key={`lane-${i}-slot-${l}`}
                >
                  <div
                    className={cn({
                      'hover:shadow-lead-xl hover:border-primary-400 relative h-full rounded-md border-2 border-transparent bg-slate-900 px-4 transition-all':
                        true,
                      'py-3': spans > 1,
                      'border-primary-400 shadow-lead-xl': item.active,
                      'flex items-center': spans <= 1,
                      'cursor-pointer': typeof item.onClick === 'function',
                    })}
                    onClick={() => item.onClick?.()}
                  >
                    <div className="flex items-start justify-between">
                      <div>
                        <span className="block font-semibold text-white">
                          {item.title}
                        </span>
                        {item.subTitle && (
                          <span className="mb-1 block text-sm">
                            {item.subTitle}
                          </span>
                        )}
                      </div>

                      {item.speakers && item.speakers.length > 0 && (
                        <div className="mt-1 flex gap-2">
                          {item.speakers.map((speaker) => {
                            return (
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger>
                                    <GradientCard
                                      outerProps={{
                                        className:
                                          'h-10 w-10 overflow-hidden rounded-full',
                                      }}
                                    >
                                      {speaker.image && (
                                        <Image
                                          width={100}
                                          height={100}
                                          className="h-full w-full object-cover object-center"
                                          src={speaker.image}
                                          alt={speaker.name ?? ''}
                                        ></Image>
                                      )}
                                    </GradientCard>
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    <p>{speaker.name}</p>
                                    <p className="text-xs">{speaker.role}</p>
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            )
                          })}
                        </div>
                      )}
                    </div>
                    {item.payment && (
                      <div className="border-1 mt-10 inline-block space-y-2 rounded-lg border-dashed border-white/40 bg-white/10 p-4">
                        <p className="text-2xl font-bold">
                          <span className="text-white">
                            {Number(item.payment.price)} EUR
                          </span>
                          <span className="ml-2 text-xs">/ seat excl. VAT</span>
                        </p>
                        {item.payment.link && (
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>
                                <Button
                                  asChild
                                  variant="lead"
                                >
                                  <Link
                                    target="_blank"
                                    href={item.payment.link}
                                  >
                                    Secure seat now
                                  </Link>
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent className="max-w-[300px] bg-red-600 text-center">
                                Please remove the masterclasses you don't want
                                to attend in the checkout by changing the
                                quantity to 0. Remove both and keep the bundle,
                                if you want to attend both!
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        )}
                        <p className="italic">
                          Limited to only {item.payment.seats} seats. <br />
                          First come, first served. Act fast!
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
            {totalSlotsRendered < maxSlots && (
              <div
                className={cn({
                  'border-t border-dashed border-slate-800 p-2 ': true,
                })}
                style={{
                  gridRow: `span ${maxSlots - totalSlotsRendered} / span ${maxSlots - totalSlotsRendered}`,
                }}
              ></div>
            )}
          </Fragment>
        )
      })}
    </div>
  )
}
