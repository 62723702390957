'use client'

import type {
  ArticleStoryblok,
  PersonStoryblok,
} from '@vendure/codegen/storyblok'
import { Badge } from '@vendure/components/ui'
import moment from 'moment'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { StoryblokStory } from 'storyblok-generate-ts'
import {
  CustomImage,
  CustomLink,
  GradientCard,
  Heading,
  HorizontalPersonCard,
} from '../atoms'
import { PreTitle } from '../atoms/PreTitle'

interface IArticleCard {
  article: StoryblokStory<ArticleStoryblok>
}

export const ArticleCard = ({ article }: IArticleCard) => {
  const intl = useIntl()
  const isNew = useMemo(() => {
    const diff = moment().diff(moment(article.first_published_at), 'days')
    return diff < 14
  }, [article.first_published_at])

  const { content: articleContent } = article

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (articleContent === undefined) {
    return <p>No content given</p>
  }

  const { content: category } =
    typeof articleContent.category !== 'string'
      ? articleContent.category!
      : { content: null }

  return (
    <div className="transition-all hover:opacity-80">
      <CustomLink href={article.full_slug}>
        <div className="flex flex-col gap-6">
          {articleContent.featuredImage?.filename && (
            <GradientCard outerProps={{ className: 'relative aspect-video' }}>
              <CustomImage
                width={800}
                height={600}
                className={'h-full w-full object-cover object-center'}
                src={articleContent.featuredImage.filename}
                alt={articleContent.featuredImage.alt ?? ''}
              />
              {isNew && (
                <Badge
                  className="absolute bottom-4 right-3"
                  variant="primary"
                >
                  {intl.formatMessage({ id: 'blog_article.flag.is_new' })}
                </Badge>
              )}
            </GradientCard>
          )}
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                {articleContent.category && (
                  <PreTitle>{category?.name}</PreTitle>
                )}
                {articleContent.readTime && (
                  <p className="font-xs font-semibold">
                    {intl.formatMessage({ id: 'blog_article.label.read_time' })}
                    : {articleContent.readTime}min
                  </p>
                )}
              </div>
              <Heading
                className="mt-2 text-2xl"
                as="p"
              >
                {articleContent.title}
              </Heading>
            </div>
            <div className="prose prose-slate">{articleContent.excerpt}</div>
          </div>
          {articleContent.author && (
            <HorizontalPersonCard
              person={articleContent.author as StoryblokStory<PersonStoryblok>}
            />
          )}
        </div>
      </CustomLink>
    </div>
  )
}
